<template>
<div class="station-alarm-container">
  <div class="device-data-area">
    <div class="alarm-data-query">
      {{showLang('com.export.cn.connid')}}：
      <Input type="text" v-model="filter.code" clearable :placeholder="showLang('com.export.cn.connid')" style="width: 80px; margin-right: 10px;"></Input>
      备注信息：
      <Input type="text" v-model="filter.remark" clearable placeholder="备注信息" style="width: 140px; margin-right: 10px;"></Input>
      ICCID：
      <Input type="text" v-model="filter.iccid" clearable placeholder="ICCID" style="width: 100px; margin-right: 10px;"></Input>
      IMEI：
      <Input type="text" v-model="filter.imei" clearable placeholder="IMEI" style="width: 100px; margin-right: 10px;"></Input>
      硬件版本：
      <Input type="text" v-model="filter.hardware" clearable placeholder="硬件版本" style="width: 100px; margin-right: 10px;"></Input>
      软件版本：
      <Input type="text" v-model="filter.software" clearable placeholder="软件版本" style="width: 100px; margin-right: 10px;"></Input>
      开始时间：
      <DatePicker v-model="filter.start" format="yyyy-MM-dd" type="date" placeholder="开始日期" style="width: 115px;margin-right:10px"></DatePicker>
      结束时间：
      <DatePicker v-model="filter.end" format="yyyy-MM-dd" type="date" placeholder="结束日期" style="width: 115px;margin-right:10px"></DatePicker>
      <Button v-if="funCodes(9970)" size="default" type="primary" @click="search" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
      <Button v-if="funCodes(9971)" size="default" type="error" @click="deleteDevice" style="margin-right: 10px;">删除</Button>
      <span class="test-count-area">库存数量：{{ total }}</span>
    </div>
    <div class="alarm-data-area" ref="table">
      <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" :key="dataRefresh" stripe round
        @checkbox-change="selectChangeEvent" :data="devices" height="auto" :row-config="{isHover: true, height: rowHeight}" :loading="loading"
        :checkbox-config="{checkField: 'checked',highlight: true}">
        <vxe-column type="checkbox" width="60" fixed="left"></vxe-column>
        <vxe-column field="code" :title="showLang('com.export.cn.id')" fixed="left" width="100" sortable header-align="center"></vxe-column>
        <vxe-column field="statusTime" title="入库时间" width="150" sortable header-align="center"></vxe-column>
        <vxe-column field="remark" title="入库说明" sortable header-align="center"></vxe-column>
        <vxe-column field="iccid" title="ICCID" width="180" sortable header-align="center"></vxe-column>
        <vxe-column field="imei" title="IMEI" width="150" sortable header-align="center"></vxe-column>
        <vxe-column field="hardware" title="硬件版本" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="software" title="软件版本" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="appcode" title="应用编号" width="200" sortable header-align="center"></vxe-column>
        <vxe-column field="version" title="应用版本" width="200" sortable header-align="center"></vxe-column>
      </vxe-table>
    </div>
    <vxe-pager :layouts="pageLayouts" :current-page.sync="filter.index" :page-size.sync="filter.size" :total="total" :page-sizes='pageSizes' @page-change="handlePageChange"></vxe-pager>
  </div>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'CommandChannelIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      loading: false,
      rowHeight: 48,
      filter: {
        remark: "",
        code: "",
        hardware: "",
        software: "",
        iccid: "",
        imei: "",
        start: '',
        end: '',
        size: 20,//20 100 500 1000 5000
        index: 1,
      },
      args: {
        plan1s: [],
        plan2s: [],
        useTypes: [],
        modes: [],
        gateways: [],
        deviceTypes: [],
        groups: [],
      },
      originalDevices: [],
      clearOldLog: this.clearOldLogs,
      cmdType: 'light',
      showCheckAuthModal: false,
      checkAuthModalSuccessFunction: null,
      checkAuthModalUsers: [],
      showWaitingModal: false,
      showArgModal: false,
      showPlanModal: false,
      showCreateModal: false,
      showStartOtaModal: false,
      cmdItem: { item: {}, other: {}, args: {} },
      cmdcnt: 15,
      devices: [],
      total: 0,
      dataRefresh: 0,
      arrCmds: [],
      cmdResults: {},
      timer: null,
      menus: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes','showLang']),
    ...mapState('auth', ['config']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs']),
    ...mapState('common', ['pageLayouts', 'pageSizes', 'coms6038names']),
    ...mapState('group', ['groups', 'groupTreeSelectedNode']),
  },
  watch: {
    clearOldLog() {
      this.$store.commit('cmd/setOldLogStatus', this.clearOldLog)
    },
  },
  mounted: function () {
    let d = new Date();
    // d.setDate(d.getDate() + 1);
    this.filter.end = d.todayEnd();
    d.setMonth(d.getMonth() - 3);
    this.filter.start = d.todayStart();
    this.search();
  },
  destroyed: function () {
  },
  methods: {
    deleteDevice: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning("请选择要控制的单灯");
        return;
      }
      let list = chks.map(p => p.code);
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.record'),
        onOk: async () => {
          this.$axios.post(`site/debug/DeleteLightStore`, { list: list }).then(res => {
            if (res.code == 0) {
              this.initList();
            }
          });
        }
      });
    },
    handlePageChange({ currentPage, pageSize }) {
      this.filter.index = currentPage
      this.filter.size = pageSize
      this.initList()
    },
    search: function(){
      this.filter.index = 1;
      this.initList();
    },
    initList: function () {
      if(this.loading)return;
      this.filter.start = new Date(this.filter.start).todayStart();
      this.filter.end = new Date(this.filter.end).todayEnd();
      this.loading = true;
      this.$axios.post(`site/debug/QueryLightStore`, this.filter).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.$set(this, 'total', res.data.count);
          this.$set(this, 'devices', res.data.list);
        }
      });
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
    },
  }
}
</script>
<style scoped>
.station-alarm-container {
  height: 100%;
  display: flex;
  /* flex-direction: column;
  align-content: stretch; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.config-tree-area{
  /* border: solid 1px #dcdee2; */
  width: 300px !important;
  flex: none;
  border-radius: 6px;
}
.device-data-area {
  margin-left: 10px;
  border: solid 1px #dcdee2;
  width: 300px;
  flex: auto;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.alarm-data-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  border-radius: 6px;
  padding: 0 5px;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
.btns-flex {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}
.split-row-area{
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
}
.split-row-item{
  /* border-bottom: solid 1px red; */
  height: 30px;
  flex: none;
  line-height: 30px;
  text-align: center;
}
.split-row-flag{
  border-bottom: solid 1px #dcdee2;
}
</style>